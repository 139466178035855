import React, { ReactNode, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { ContactType, CorporateAccount } from '@lib/state';
import { Section, SectionVariant } from '../../components';
import { FormCheckbox, FormTextField, useFormPrefix } from '../../forms';
import { useFeatureEnabled } from '../../utils';
import { SleepScheduleInput } from './sleep-schedule-input';
import { GuestEntryForm } from './guest-entry.form';
import { useBookingValidationQuery } from './useBookingValidationQuery';
import { BookingValidationDisplay } from './booking-validation-display';

interface GuestFormProps {
  propertyId?: string;
  corporateBooking?: CorporateAccount;
}

export interface GuestEntryProps extends GuestFormProps {
  isPrimary: boolean;
  guestCount: number;
  prefix: string;
  disabled?: boolean;
  useRearCam: boolean;
}

interface RoomFormProps extends GuestFormProps {
  maxGuestCount: number;
  guestEntryForm: (props: GuestEntryProps) => ReactNode;
  useRearCam: boolean;
  isSingle?: boolean;
}

interface Props extends RoomFormProps {
  className?: string;
  variant?: SectionVariant;
  roomQuantity: number;
}

export const RoomListForm: React.FC<Props> = ({
  className,
  variant,
  corporateBooking,
  roomQuantity,
  maxGuestCount,
  guestEntryForm,
  propertyId,
  useRearCam,
  isSingle,
  ...guestFormProps
}) => {
  const { watch, setValue, register } = useFormContext();

  // check if the property allows multiple rooms under a single reservation
  const allowMultiRoomBooking = useFeatureEnabled('AllowMultiRoomBooking');

  const contactType = watch(
    'contact.contactType',
    corporateBooking ? ContactType.Delegate : ContactType.Guest
  );

  const guestTbd = watch('guestTbd', false);

  useEffect(() => {
    if (guestTbd) {
      setValue(`rooms[${roomQuantity - 1}].sleepSchedule`, 'None');
    }
  }, [guestTbd, setValue]);

  // implemented as such since we might allow the user to toggle this behavior if permitted
  useEffect(() => {
    register('contact.contactType');
    register('multipleBooking');
    register('guestTbd');
  }, [register]);

  useEffect(() => setValue('multipleBooking', !allowMultiRoomBooking), [
    setValue,
    allowMultiRoomBooking,
  ]);

  useEffect(() => {
    const contactType = corporateBooking ? ContactType.Delegate : ContactType.Guest;
    setValue('contact.contactType', contactType);
  }, [setValue, corporateBooking]);

  return (
    <>
      {[...Array(roomQuantity)].map((_, roomGroup) => (
        <Section
          className={className}
          title={`${isSingle ? 'Room' : 'Guest'} ${roomGroup + 1}`}
          maxWidth="md"
          variant={variant}
          key={`room_${roomGroup}`}
          banner={
            allowMultiRoomBooking &&
            roomGroup === 0 &&
            roomQuantity > 1 && (
              <Alert severity="info">
                All of your rooms will be booked under a single reservation with the same check-in
                and check-out dates.
              </Alert>
            )
          }
          data-testid={`roomFormItem:${roomGroup + 1}`}
        >
          {roomGroup === 0 && (
            <RadioGroup
              value={contactType}
              onChange={(_, value) => setValue('contact.contactType', value)}
            >
              <FormControlLabel
                value={ContactType.Guest}
                control={<Radio data-testid="roomBookForSelfRadio" />}
                label="Book this room for myself"
              />
              <FormControlLabel
                value={ContactType.Delegate}
                control={<Radio data-testid="roomBookForAnotherRadio" />}
                label="Book this room for another guest"
              />
            </RadioGroup>
          )}
          <FormTextField
            type="hidden"
            name={`rooms[${roomGroup}].group`}
            defaultValue={roomGroup}
          />
          <RoomGuestList
            roomGroup={roomGroup}
            maxGuestCount={maxGuestCount}
            isDelegateBooking={contactType === ContactType.Delegate}
            corporateBooking={corporateBooking}
            guestEntryForm={props => (
              <GuestEntryForm {...props} disabled={roomQuantity === roomGroup + 1 && guestTbd} />
            )}
            propertyId={propertyId}
            useRearCam={useRearCam}
            disabled={roomQuantity === roomGroup + 1 && guestTbd}
            isSingle={isSingle}
            {...guestFormProps}
          />
          {!allowMultiRoomBooking && corporateBooking?.enableSleepSchedule && (
            <Box py={1}>
              <Typography variant="subtitle1">Room Options</Typography>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <SleepScheduleInput
                    name={`rooms[${roomGroup}].sleepSchedule`}
                    label="Sleep Schedule"
                    fullWidth
                    disabled={roomQuantity === roomGroup + 1 && guestTbd}
                    validationOptions={{
                      required: 'This field is required.',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {!isSingle && roomGroup !== 0 && roomQuantity === roomGroup + 1 && (
            <FormCheckbox name={'guestTbd'} label="TBD" defaultChecked={false} />
          )}
        </Section>
      ))}
    </>
  );
};

interface RoomGuestListProps extends RoomFormProps {
  roomGroup: number;
  isDelegateBooking: boolean;
  disabled?: boolean;
}

const RoomGuestList: React.FC<RoomGuestListProps> = ({
  roomGroup,
  maxGuestCount,
  isDelegateBooking,
  guestEntryForm,
  corporateBooking,
  isSingle,
  disabled,
  ...guestFormProps
}) => {
  const { watch, register, setValue } = useFormContext();

  const [guestCount, setGuestCount] = useState(1);

  const fieldName = useFormPrefix(`rooms[${roomGroup}]`);

  const roomTypeId = watch('room.roomTypeId');
  const dailyRates = watch('room.dailyRates');

  const { data: validation } = useBookingValidationQuery({
    corporateAccountId: corporateBooking?.id,
    guests: watch(fieldName('guests')),
  });

  useEffect(() => {
    register(fieldName(`rate`));
  }, [fieldName]);

  useEffect(() => {
    setValue(fieldName(`rate`), { roomTypeId, dailyRates });
  }, [roomTypeId, dailyRates, fieldName, setValue]);

  return (
    <>
      {[...Array(guestCount)].map(
        (_, guestIndex) =>
          (isDelegateBooking || guestIndex !== 0 || roomGroup > 0) && (
            <Box py={1} key={`room_${roomGroup}_guest${guestIndex}`}>
              <Typography variant="subtitle1">
                Guest {isSingle ? guestIndex + 1 : 'contact'} {guestIndex === 0 ? '' : '(Optional)'}
              </Typography>
              {guestEntryForm({
                guestCount,
                isPrimary: guestIndex === 0,
                prefix: fieldName(`guests[${guestIndex}]`),
                corporateBooking,
                ...guestFormProps,
              })}
            </Box>
          )
      )}
      {!!validation && <BookingValidationDisplay validation={validation} />}
      {guestCount < maxGuestCount && (
        <Box pt={1}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setGuestCount(Math.min(maxGuestCount, 1 + guestCount))}
            disabled={disabled}
          >
            Add New Guest
          </Button>
        </Box>
      )}
    </>
  );
};
