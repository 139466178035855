import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, MenuItem } from '@material-ui/core';

import { CorporateAccount, CostCenterFieldStatus } from '@lib/state';
import {
  EnumValueDisplay,
  FormCheckbox,
  FormNumberField,
  FormSelect,
  FormTextField,
  FormTimePicker,
  Section,
} from '@lib/common';
import { BillingFrequencyType, PaymentTerm } from '@lib/state/api/generated/reservations';

interface Props {
  account?: CorporateAccount;
}

export const CorporateAccountInformationForm: React.FC<Props> = ({ account }) => {
  const [showTaxCreditSplit, setShowTaxCreditSplit] = useState(false);
  const [propertyTaxCreditSplitValue, setPropertyTaxCreditSplitValue] = useState(
    account?.taxCreditSplit
  );

  const { register, setValue, errors, watch } = useFormContext();

  const allowTaxCredit = watch('allowTaxCredit', false);
  const directBillingEnabled = watch('isInvoiceEnabled', false);

  useEffect(() => {
    setShowTaxCreditSplit(allowTaxCredit === true);
  }, [allowTaxCredit]);

  useEffect(() => {
    if (directBillingEnabled)
      setValue(
        'billingFrequencyType',
        account?.billingFrequencyType !== BillingFrequencyType.NA
          ? account?.billingFrequencyType
          : BillingFrequencyType.Weekly
      );
    else setValue('billingFrequencyType', BillingFrequencyType.NA);
  }, [directBillingEnabled, account, setValue]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <FormTextField
            name="name"
            label="Name"
            defaultValue={account?.name}
            fullWidth
            validationOptions={{ required: 'This field is required.' }}
            required
            data-testid="corporateAccountNameInput"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormTextField
            name="invoiceSubAccount"
            label="Invoice Account"
            defaultValue={account?.invoiceSubAccount}
            fullWidth
            required={account ? true : false}
            validationOptions={{
              pattern: /^[a-zA-Z0-9]{0,8}$/,
            }}
            helperText={errors =>
              errors.invoiceSubAccount?.type === 'pattern'
                ? 'Invoice account has a max length of 8 characters and must be alphanumeric.'
                : account
                ? ''
                : 'If left blank will be automatically generated based on name'
            }
            data-testid="corporateAccountInvoiceSubInput"
          />
        </Grid>
        {!!account?.id && (
          <>
            <Grid item md={6} xs={12}>
              <FormTextField
                name="reportRecipients"
                label="Report Recipients"
                defaultValue={account?.reportRecipients}
                fullWidth
                validationOptions={{
                  pattern: /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,10}[\W]*;{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,10})[\W]*$/,
                }}
                helperText={errors =>
                  errors.reportRecipients?.type === 'pattern'
                    ? 'Please enter a semicolon separated list ex. jdoe@domain.com; jdoe2@domain.com'
                    : 'Report Recipients are used for email report delivery instead of using the delegate email addresses.'
                }
                data-testid="corporateReportRecipientsInput"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormTextField
                name="bookingRecipients"
                label="Booking Recipients"
                defaultValue={account?.bookingRecipients}
                fullWidth
                validationOptions={{
                  pattern: /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,10}[\W]*;{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,10})[\W]*$/,
                }}
                helperText={errors =>
                  errors.bookingRecipients?.type === 'pattern'
                    ? 'Please enter a semicolon separated list ex. jdoe@domain.com; jdoe2@domain.com'
                    : 'Booking Recipients are copied for booking notifications (create, update, cancel).'
                }
                data-testid="corporateBookingRecipientsInput"
              />
            </Grid>
          </>
        )}
        <Grid item md={6} xs={12}>
          <FormCheckbox
            name="disableEmployeeBooking"
            label="Disable Employee Booking"
            defaultChecked={account?.disableEmployeeBooking}
            fullWidth
            helperText={
              errors.disableEmployeeBooking?.message ??
              'If enabled, then front desk and manager employees cannot book on behalf of any delegate assigned to this corporate account.'
            }
            data-testid="corporateDisableEmployeeBookingCheckbox"
          />
        </Grid>
        {!!account?.id && (
          <>
            <Grid item md={3} xs={12}>
              <FormCheckbox
                name="sendDailyHousingReport"
                label="Send Daily Housing Report"
                defaultChecked={account?.sendDailyHousingReport}
                fullWidth
                data-testid="corporateDailyReportCheckbox"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormCheckbox
                name="enableSleepSchedule"
                label="Enable Sleeping Schedules"
                defaultChecked={account?.enableSleepSchedule}
                fullWidth
                helperText={
                  errors.enableSleepSchedule?.message ??
                  'If enabled, then Day or Night Sleeper must be selected for the guest while booking.'
                }
                data-testid="corporateSleepScheduleCheckbox"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormCheckbox
                name="isAutomaticLateCheckoutEnabled"
                label="Automatic Late Checkout For Day Sleepers"
                defaultChecked={account?.isAutomaticLateCheckoutEnabled}
                fullWidth
                helperText={
                  errors.isAutomaticLateCheckoutEnabled?.message ??
                  'If enabled, then Late Checkout is applied automatically for Day Sleepers when booking.'
                }
                data-testid="corporateLateCheckoutCheckbox"
              />
            </Grid>
          </>
        )}
      </Grid>
      {!!account?.id && (
        <Section title="Billing Policy" textAlign="left">
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <FormCheckbox
                name="isInvoiceEnabled"
                label="Direct Billing"
                defaultChecked={account?.isInvoiceEnabled}
                fullWidth
                helperText={
                  errors.isInoviceEnabled?.message ??
                  'If enabled, then the option to use an Invoice Payment method is available when booking.'
                }
                data-testid="corporateInvoiceCheckbox"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <FormSelect
                name="paymentTerm"
                label="Payment term"
                fullWidth
                defaultValue={account?.paymentTerm}
                data-testid="corporatePaymentTermSelect"
              >
                <MenuItem
                  value={PaymentTerm.DueOnReceipt}
                  data-testid="corporatePaymentItem:Receipt"
                >
                  Due On Receipt
                </MenuItem>
                <MenuItem value={PaymentTerm.Net15} data-testid="corporatePaymentItem:15">
                  Net 15
                </MenuItem>
                <MenuItem value={PaymentTerm.Net30} data-testid="corporatePaymentItem:30">
                  Net 30
                </MenuItem>
                <MenuItem value={PaymentTerm.Net45} data-testid="corporatePaymentItem:45">
                  Net 45
                </MenuItem>
                <MenuItem value={PaymentTerm.Net60} data-testid="corporatePaymentItem:60">
                  Net 60
                </MenuItem>
              </FormSelect>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormTextField
                name="billId"
                label="Bill ID"
                defaultValue={account?.billId}
                fullWidth
                validationOptions={{
                  maxLength: 3,
                  minLength: 3,
                  required: false,
                }}
                data-testid="corporateBillIdInput"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormNumberField
                name="automaticNoShowDays"
                label="No Show Hold Days"
                fullWidth
                defaultValue={account?.automaticNoShowDays}
                validationOptions={{
                  min: {
                    value: 1,
                    message: 'Value must be greater than zero.',
                  },
                }}
                helperText={
                  errors.automaticNoShowDays?.message ??
                  'Number of days held/charged prior to cancellation after the check-in date.'
                }
                data-testid="corporateNoShowHoldInput"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormSelect
                defaultValue={account.billingFrequencyType}
                name="billingFrequencyType"
                label="Billing Frequency"
                fullWidth
              >
                <MenuItem
                  key={BillingFrequencyType.NA}
                  value={BillingFrequencyType.NA}
                  dense
                  disabled={directBillingEnabled}
                >
                  <EnumValueDisplay value={BillingFrequencyType.NA} disableTypography />
                </MenuItem>
                {Object.values(BillingFrequencyType)
                  .filter(b => b !== BillingFrequencyType.NA)
                  .map(x => (
                    <MenuItem key={x} value={x} dense>
                      <EnumValueDisplay value={x} disableTypography />
                    </MenuItem>
                  ))}
              </FormSelect>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormTimePicker
                disableToolbar
                defaultValue={account.automaticNoShowTime}
                name="automaticNoShowTime"
                label="No Show Scheduled At Time"
                autoOk
                fullWidth
                ampm={false}
                format="HH:mm"
                clearable
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <FormCheckbox
                name="allowTaxCredit"
                label="Allow Tax Credit"
                defaultChecked={account?.allowTaxCredit}
                fullWidth
                helperText={
                  'If enabled, then the corporate account will be eligible to receive tax credit'
                }
                data-testid="corporateAllowTaxCreditCheckbox"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              {showTaxCreditSplit && (
                <FormNumberField
                  name="taxCreditSplit"
                  label="Property Tax Credit Split"
                  defaultValue={account.taxCreditSplit}
                  validationOptions={{
                    required: showTaxCreditSplit,
                    min: 0,
                    max: 100,
                  }}
                  onChangeCapture={(e: any) => setPropertyTaxCreditSplitValue(e.target.value)}
                  helperText={errors => {
                    const creditSplitRemainder = propertyTaxCreditSplitValue
                      ? 100 - propertyTaxCreditSplitValue
                      : 100;
                    const creditSplitMessage = `${propertyTaxCreditSplitValue}% will go to the property while ${creditSplitRemainder}% will go to the corporate account.`;
                    return errors.taxCreditSplit?.type === 'required'
                      ? 'This field is required.'
                      : errors.taxCreditSplit?.type === 'min' ||
                        errors.taxCreditSplit?.type === 'max'
                      ? 'Enter values between 0 and 100'
                      : creditSplitMessage;
                  }}
                  fullWidth
                  data-testid="corporateTaxCreditInput"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}></Grid>
            {account?.fields.map((x, i) => (
              <Grid key={x.key} item md={3} xs={12}>
                <input
                  type="hidden"
                  name={`fields[${i}].key`}
                  ref={register}
                  defaultValue={x.key}
                />
                <input
                  type="hidden"
                  name={`fields[${i}].label`}
                  ref={register}
                  defaultValue={x.label}
                />
                <FormSelect
                  name={`fields[${i}].status`}
                  label={x.label}
                  fullWidth
                  defaultValue={x.status}
                >
                  <MenuItem value={CostCenterFieldStatus.Disabled}>Disabled</MenuItem>
                  <MenuItem value={CostCenterFieldStatus.Enabled}>Enabled</MenuItem>
                  <MenuItem value={CostCenterFieldStatus.Required}>Required</MenuItem>
                </FormSelect>
              </Grid>
            ))}
          </Grid>
        </Section>
      )}
    </>
  );
};
