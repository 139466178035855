import React, { useCallback } from 'react';
import { FormHub, Section, useFormEvents, useObservable, usePageTitle } from '@lib/common';
import { UnbilledReservationsReport } from '../components';
import { Container } from '@material-ui/core';
import { AuditLogs, getDate } from 'app/shared';
import { CommonForm, propertyQuery, sessionQuery } from '@lib/state';
import { reportService } from 'app/state';

export interface UnbilledReservationsReportFields {
  startDate: string;
  endDate: string;
  recipients: string;
}

export const UnbilledReservationsPage: React.FC = () => {
  usePageTitle('Unbilled Reservation Report');
  const [{ status, error }, resetUI] = useFormEvents(CommonForm.Export);

  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const customerId = useObservable(propertyQuery.activeProperty)?.customerId;

  const onSubmit = useCallback(
    (fields: UnbilledReservationsReportFields) => {
      if (propertyId) {
        reportService.exportUnbilledReservations(
          propertyId,
          getDate(fields.startDate),
          getDate(fields.endDate),
          fields.recipients
        );
      }
    },
    [propertyId]
  );

  if (!propertyId) return null;

  return (
    <Container maxWidth="xl">
      <Section title="Unbilled Reservations Report">
        <FormHub<UnbilledReservationsReportFields> onSubmit={onSubmit}>
          <UnbilledReservationsReport status={status} resetFormUI={resetUI} error={error} />
        </FormHub>
      </Section>
      <Section title="Audit Logs" disableGutters>
        <AuditLogs scope="unbilled-reservation-report" id={customerId} />
      </Section>
    </Container>
  );
};
