import React, { useMemo } from 'react';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { parseISO } from 'date-fns';
import {
  FeeCharge,
  ReservationRoom,
  RoomType,
  RefundReservationChargeModel,
  Reservation,
  Accounting,
  ReservationRoomRateModel,
} from '@lib/state';
import {
  DateDisplay,
  CurrencyDisplay,
  EnumValueDisplay,
  useHasRole,
  DataTable,
  IDataTableColumn,
} from '@lib/common';
import { JournalAccount } from 'app/state';
import { RefundStayDateButton } from './refund-stay-date-button';
import { FeeChargeModel } from '@lib/state/api/generated/reservations';

interface Props {
  reservation: Reservation;
  room: ReservationRoom;
  roomType?: RoomType;
  fees: FeeCharge[];
  checkOutDate: string;
  accounts: JournalAccount[];
  charges: Reservation['charges'];
  refundCharge: (model: RefundReservationChargeModel) => void;
}

interface RoomCharge {
  id: string;
  type: string;
  date?: string;
  amount: number;
  tax: number;
  total: number;
  refunded: boolean;
  ratePlan: string;
  reservationRoomId: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    feeTable: {
      tableLayout: 'fixed',
    },
  })
);

export const ReservationRoomBreakdown: React.FC<Props> = ({
  reservation,
  room,
  roomType,
  fees,
  charges,
}) => {
  const { feeTable } = useStyles();

  const roomCharges = useMemo(() => {
    const refundCharges = charges.filter(r => r.totalAmount < 0);

    const refundAmount = refundCharges.map(r => r.amount)?.reduce((total, a) => total + a, 0) ?? 0;
    const taxTotal = refundCharges.map(r => r.tax.total)?.reduce((total, a) => total + a, 0) ?? 0;
    const refund = refundCharges.map(r => r.totalAmount)?.reduce((total, a) => total + a, 0) ?? 0;

    const primaryTotal = getTotalRow('Total', room.rates, fees);
    const refundTotal = {
      id: 'refundTotal',
      type: 'Refund Total',
      amount: refundAmount,
      tax: taxTotal,
      total: refund,
      refunded: false,
      ratePlan: '',
      reservationRoomId: '',
    };
    const updatedTotal = getTotalRow('Updated Total', room.rates, fees);

    updatedTotal.amount += refundAmount;
    updatedTotal.tax += taxTotal;
    updatedTotal.total += refund;

    const rows: RoomCharge[] = room.rates.map((rate, i) => ({
      id: `room-${i}`,
      type: 'Room',
      date: rate.date,
      amount: rate.amount,
      tax: (rate.tax?.total ?? 0) + (rate.salesTax?.total ?? 0),
      total: rate.total,
      ratePlan: rate.ratePlan,
      refunded: rate.refunded,
      reservationRoomId: rate.reservationRoomId,
    }));

    const groupedFees = (feeItems: Array<FeeChargeModel>): Array<FeeChargeModel> => {
      const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
        list.reduce((previous, currentItem) => {
          const group = getKey(currentItem);
          if (!previous[group]) previous[group] = [];
          previous[group].push(currentItem);
          return previous;
        }, {} as Record<K, T[]>);

      const results: Array<FeeChargeModel> = [];

      Object.entries(groupBy(feeItems, k => k.type)).forEach(value => {
        const r = value[1].reduce((p, c) => {
          return {
            ...c,
            tax: { ...c.tax, total: p.tax.total + c.tax.total },
            total: p.total + c.total,
            amount: p.amount + c.amount,
          };
        });
        results.push(r);
      });

      return results;
    };

    const gFees = groupedFees(fees);

    gFees.forEach((fee, i) =>
      rows.push({
        id: `fee-${i}`,
        type: fee.type,
        amount: fee.amount,
        tax: fee.tax.total,
        total: fee.total,
        ratePlan: '',
        refunded: false,
        reservationRoomId: fee.reservationRoomId,
      })
    );

    rows.push(...[primaryTotal, refundTotal, updatedTotal]);

    return rows;
  }, [room, fees, charges]);

  const columns: IDataTableColumn<Partial<RoomCharge>>[] = [
    {
      title: 'Type',
      valueFactory: charge => <EnumValueDisplay value={charge.type} />,
    },
    {
      title: 'Date',
      valueFactory: charge => (
        <DateDisplay date={charge.date ? parseISO(charge.date) : null} dateFormat="M/d/yyyy" />
      ),
    },
    {
      title: 'Rate',
      align: 'right',
      valueFactory: charge => <CurrencyDisplay value={charge.amount} />,
    },
    {
      title: 'Tax',
      align: 'right',
      valueFactory: charge => <CurrencyDisplay value={charge.tax} />,
    },
    {
      title: 'Total',
      align: 'right',
      valueFactory: charge => <CurrencyDisplay value={charge.total} />,
    },
    {
      title: 'Rate Plan',
      align: 'right',
      printable: false,
      valueFactory: charge => charge.ratePlan,
    },
  ];

  if (useHasRole(Accounting)) {
    columns.push({
      title: 'Refund',
      align: 'right',
      printable: false,
      valueFactory: charge => {
        const rate = room.rates.find(
          ({ reservationRoomId, date }) =>
            reservationRoomId === charge.reservationRoomId && date === charge.date
        );
        if (!rate) return null;
        return <RefundStayDateButton roomRate={rate} reservationId={reservation.id} />;
      },
    });
  }

  return (
    <Box mb={2}>
      <Typography variant="h5">
        Room {room.sequence + 1} - {roomType?.name}
      </Typography>
      <DataTable
        items={roomCharges}
        getItemKey={row => row.id}
        columns={columns}
        tableClass={feeTable}
      />
    </Box>
  );
};

function getTotalRow(title: string, rates: Array<ReservationRoomRateModel>, fees: FeeCharge[]) {
  let amountTotal = 0;
  let salesTaxTotal = 0;
  let taxTotal = 0;
  let totalTotal = 0;

  rates.forEach(({ amount, tax, salesTax, total }) => {
    amountTotal += amount;
    salesTaxTotal += salesTax.total;
    taxTotal += tax.total + salesTaxTotal;
    totalTotal += total;
  });

  fees.forEach(fee => {
    amountTotal += fee.amount;
    salesTaxTotal = 0;
    taxTotal += fee.tax.total;
    totalTotal += fee.total;
  });

  return {
    id: title.replaceAll(' ', ''),
    type: title,
    amount: amountTotal,
    tax: taxTotal,
    total: totalTotal,
    refunded: false,
    ratePlan: '',
    reservationRoomId: '',
  };
}
