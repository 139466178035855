import { QueryEntity, Order } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';
import {
  CorporateContactState,
  CorporateContactStore,
  corporateContactStore,
} from './corporate-contact.store';
import { CorporateAccountQuery, corporateAccountQuery } from '../corporate-account';

export class CorporateContactQuery extends QueryEntity<CorporateContactState> {
  constructor(
    protected store: CorporateContactStore,
    private readonly accounts: CorporateAccountQuery
  ) {
    super(store);
  }

  contacts = this.accounts.selectActive().pipe(
    switchMap(account =>
      this.selectAll({
        filterBy: ({ corporateAccount }) => corporateAccount?.id === account?.id,
        sortBy: (a, b) => {
          return (a?.name?.first ?? '').localeCompare(b?.name?.first ?? '');
        },
        sortByOrder: Order.DESC,
      })
    )
  );

  loading = this.selectLoading();
}

export const corporateContactQuery = new CorporateContactQuery(
  corporateContactStore,
  corporateAccountQuery
);
