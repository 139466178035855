import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CorporateAccount, GuestProfile } from '@lib/state';
import {
  AddressForm,
  ContactInformationForm,
  NameForm,
  PasswordForm,
  Section,
  SectionVariant,
} from '../../components';
import { useCurrentUser } from '../../utils';
import { GuestPictureContainerComponent } from '../guest-picture-container-component';
import { CostCenterForm } from './cost-center.form';
import { GuestEntryForm } from './guest-entry.form';
import { RoomListForm } from './room-form';

interface Props {
  useRearCam: boolean;
  className?: string;
  guest?: GuestProfile | null;
  variant?: SectionVariant;
  corporateBooking?: CorporateAccount;
  quantity: number;
  guestCount: number | undefined;
  resetReservationFormStatus?: () => void;
  guestsPerRoom: number;
}

// Since we receive a collection of cost center fields, we may have other fields in the future that also need to be moved
// to a different section.
export const filteredKeys: Array<String> = ['EmployeeNumber'];

export const GuestForm: React.FC<Props> = ({
  useRearCam,
  className,
  guest,
  variant,
  corporateBooking,
  quantity,
  guestCount = 1,
  children,
  guestsPerRoom,
  resetReservationFormStatus,
}) => {
  const { setValue, watch } = useFormContext();
  const user = useCurrentUser();

  const mainContactEmail = watch('contact.contact.email');

  useEffect(() => {
    if (!!resetReservationFormStatus) resetReservationFormStatus();
  }, [resetReservationFormStatus, mainContactEmail]);

  useEffect(() => {
    if (!guest) return;
    setValue('contact', guest);
  }, [guest]);

  return (
    <React.Fragment>
      <Section className={className} title="Your Info" maxWidth="md" variant={variant}>
        {children}
        <GuestPictureContainerComponent guest={guest} prefix="contact">
          <ContactInformationForm
            prefix="contact"
            email={guest?.contact.email ?? user?.email}
            phone={guest?.contact.phone}
            isEmailDisabled={user != null}
            required="email"
          />
          <NameForm prefix="contact" name={guest?.name} disabled={!!guest} />
        </GuestPictureContainerComponent>
        <AddressForm prefix="contact" address={guest?.address} />
        {user == null && <PasswordForm />}
      </Section>
      <CostCenterForm corporateAccount={corporateBooking} className={className} variant={variant} />
      <RoomListForm
        className={className}
        variant={variant}
        corporateBooking={corporateBooking}
        roomQuantity={quantity * guestsPerRoom}
        maxGuestCount={guestCount}
        guestEntryForm={props => <GuestEntryForm {...props} />}
        useRearCam={useRearCam}
        isSingle={guestsPerRoom === 1}
      />
    </React.Fragment>
  );
};
