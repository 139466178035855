import { Store, StoreConfig, transaction } from '@datorama/akita';
import { AuthState, UserInfo } from './session.model';
import { Property } from '../property';
import { QueryClient } from 'react-query';

export interface SessionState {
  staySignedIn: boolean;
  auth: AuthState;
  user?: UserInfo;
  propertyId: Property['id'] | null;
  employeeCode: number | null;
  employeeRoles: string[];
  ui: SessionUIState;
  guestPortalUrl: string | null;
  accountingNotifications: string | null;
}

export interface SessionUIState {
  preservedSession?: SessionState;
  ignoreLandingPage: boolean;
}

const initialState: SessionState = {
  staySignedIn: false,
  auth: {
    authenticated: false,
  },
  propertyId: null,
  employeeCode: null,
  employeeRoles: [],
  ui: {
    preservedSession: undefined,
    ignoreLandingPage: false,
  },
  guestPortalUrl: null,
  accountingNotifications: null,
};

@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(initialState);
  }

  @transaction()
  reset() {
    // capture the propertyId so we can restore it after reset
    const propertyId = this.getValue().propertyId;
    super.reset();

    queryClient.clear(); // we want to clear the query cache when the user signs out

    this.update({ propertyId });
  }
}

export const sessionStore = new SessionStore();
export const queryClient = new QueryClient();
