import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';

import { InHouseGuestPage } from './in-house-guests';
import { OccupancyPage } from './occupancy';
import { PurchaseTransactionPage } from './purchases';
import { UnbilledReservationsPage } from './unbilled-reservations/pages';

export const ReportsRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Box py={2} flex={1} clone>
    <Container maxWidth="lg">
      <Switch>
        <Route path={`${match.path}/in-house-guests`} exact component={InHouseGuestPage} />
        <Route path={`${match.path}/occupancy`} exact component={OccupancyPage} />
        <Route path={`${match.path}/purchases`} exact component={PurchaseTransactionPage} />
        <Route path={`${match.path}/unbilled`} exact component={UnbilledReservationsPage} />
      </Switch>
    </Container>
  </Box>
);
