import { Reservations } from '../api';
import { Fee } from '../models.common';
import { Reservation } from '../reservation';

export interface CheckInRequirement extends Fee {
  id: string;
  reservationId: Reservation['id'];
}

export type RoomAssignment = Reservations.RoomAssignmentModel;

export type ReservationMessage = Reservations.ReservationMessageModel;

export type ReservationMessageType = Reservations.ReservationMessageType;
export const ReservationMessageType = Reservations.ReservationMessageType;

export function mapCheckInRequirement(reservationId: Reservation['id']) {
  return (requirement: Fee): CheckInRequirement => ({
    id: `${reservationId}-${requirement.type}`,
    reservationId,
    ...requirement,
  });
}
