import { applyTransaction, setLoading } from '@datorama/akita';
import { formatISO, lightFormat } from 'date-fns';
import { combineLatest, defer, EMPTY, from, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  skipWhile,
  switchMap,
  take,
  tap,
  timeout,
} from 'rxjs/operators';
import { Required } from 'utility-types';

import { Auth, isAxiosError, Reservations } from '../api';
import { CheckInForm } from '../check-in';
import { dispatchForm } from '../forms';
import { GuestProfile } from '../guest-profile';
import { formatPaymentMethod } from '../payment-method';
import { Property } from '../property';
import { SessionService, sessionService } from '../session';
import { AcceptCardData, getE164FormattedPhoneNumber, getPaymentNonce, poll } from '../utils';
import {
  Contact,
  Name,
  PaginatedQuery,
  PaginationState,
  CommonForm,
  SleepSchedule,
} from '../models.common';
import { bulkAvailableService } from '../bulk-available-rates';
import { ReservationForms } from './reservation.forms';
import {
  mapReservationModifications,
  mapUpdateCost,
  RefundReservationChargeModel,
  Reservation,
  ReservationActivity,
  ReservationChangeCostCenterFields,
  ReservationStatus,
  VerifiedGuest,
  mapReservationChangeOptions,
  mapRebookPreviewModel,
  ReservationRoomRateModel,
  mapReservationChangeBillingOptions,
  ClaimGuestContactRequest,
  reservationMapper,
  getGuests,
  mapReservationPolicy,
} from './reservation.model';
import {
  reservationStore,
  ReservationStore,
  ReservationUIState,
  BookedByUserType,
  authUserTypeFromBookedBy,
} from './reservation.store';

export class ReservationService {
  constructor(
    private readonly store: ReservationStore,
    private readonly sessionService: SessionService,
    private readonly reservationApi: Reservations.ReservationApi,
    private readonly searchApi: Reservations.ReservationSearchApi,
    private readonly accountApi: Auth.AccountApi
  ) {}

  bulkReservationsModify(
    propertyId: string,
    corporateAccountId: string,
    rooms: Array<Reservations.BulkUpdateReservationRoomsModel>,
    checkInDate?: Date,
    checkOutDate?: Date
  ) {
    const request: Reservations.BulkUpdateReservationRequest = {
      reservationRooms: rooms,
      propertyId: propertyId,
      corporateAccountId: corporateAccountId,
      checkInDate: checkInDate ? lightFormat(checkInDate, 'yyyy-MM-dd') : undefined,
      checkOutDate: checkOutDate ? lightFormat(checkOutDate, 'yyyy-MM-dd') : undefined,
    };
    from(this.reservationApi.reservationBulkModifyPost(request))
      .pipe(
        dispatchForm(ReservationForms.BulkReservationsModify),
        dispatchForm(ReservationForms.UpdateReservation),
        map(response => reservationMapper()(response.data.data))
      )
      .subscribe(res => {
        this.store.upsertMany(res);
        bulkAvailableService.resetUI();
      });
  }

  getReservationById(reservationId: Reservation['id']) {
    from(this.reservationApi.reservationIdGet(reservationId))
      .pipe(map(x => reservationMapper()(x.data.data)))
      .subscribe(reservation => this.store.upsert(reservationId, reservation));
  }

  getReservationsByIds(reservationsIds: Array<Reservation['id']>) {
    from(this.reservationApi.reservationByIdsPost({ reservationsIds: reservationsIds })).subscribe(
      response => {
        this.store.upsertMany(response.data.data);
        return response.data.data;
      }
    );
  }

  getReservationByRoomId(roomId: string) {
    from(this.reservationApi.reservationRoomRoomIdGet(roomId))
      .pipe(map(x => reservationMapper()(x.data.data)))
      .subscribe(reservation => this.store.upsert(reservation.id, reservation));
  }

  getReservations(
    {
      propertyId,
      firstName,
      recordNumber,
      recordNumberRange,
      lastName,
      crew,
      email,
      phone,
      start,
      end,
      status,
      corporateAccountName,
      corporateAccountId,
      planName,
      isFilterByZeroCharges,
      bookedBy,
      userId,
      roomTypeId,
      vipStatus,
    }: Required<ReservationUIState, 'propertyId'>,
    continuationToken?: string | null,
    limit?: number
  ) {
    from(
      this.searchApi.searchGet(
        propertyId,
        recordNumber,
        recordNumberRange,
        firstName ?? undefined,
        lastName ?? undefined,
        crew ?? undefined,
        email ?? undefined,
        phone ?? undefined,
        corporateAccountName ?? undefined,
        corporateAccountId ?? undefined,
        planName ?? undefined,
        isFilterByZeroCharges,
        bookedBy === BookedByUserType.You ? userId : undefined,
        authUserTypeFromBookedBy(bookedBy),
        roomTypeId,
        start && lightFormat(start, 'yyyy-MM-dd'),
        end && lightFormat(end, 'yyyy-MM-dd'),
        status ?? undefined,
        continuationToken ?? undefined,
        limit,
        vipStatus
      )
    )
      .pipe(
        map(response => response.data),
        map(({ data, ...page }) => ({
          data: reservationMapper(page)(data),
          ...page,
        }))
      )
      .subscribe(({ data, ...pagination }) =>
        applyTransaction(() => {
          this.store.upsertMany(data);
          this.updateUI({
            propertyId,
            recordNumber,
            recordNumberRange,
            start,
            end,
            firstName,
            lastName,
            crew,
            email,
            phone,
            status,
            corporateAccountName,
            planName,
            isFilterByZeroCharges,
            bookedBy,
            userId,
            roomTypeId,
            vipStatus,
          });
          this.updatePaginationState(pagination);
        })
      );
  }

  exportReservations({
    propertyId,
    firstName,
    recordNumber,
    recordNumberRange,
    lastName,
    crew,
    email,
    phone,
    start,
    end,
    status,
    corporateAccountName,
    corporateAccountId,
    planName,
    isFilterByZeroCharges,
    bookedBy,
    userId,
    roomTypeId,
    vipStatus,
  }: Required<ReservationUIState, 'propertyId'>) {
    from(
      this.searchApi.searchExportGet(
        propertyId,
        recordNumber ?? undefined,
        recordNumberRange ?? undefined,
        firstName ?? undefined,
        lastName ?? undefined,
        crew ?? undefined,
        email ?? undefined,
        phone ?? undefined,
        corporateAccountName ?? undefined,
        corporateAccountId ?? undefined,
        planName ?? undefined,
        isFilterByZeroCharges,
        bookedBy === BookedByUserType.You ? userId : undefined,
        authUserTypeFromBookedBy(bookedBy),
        roomTypeId,
        start && lightFormat(start, 'yyyy-MM-dd'),
        end && lightFormat(end, 'yyyy-MM-dd'),
        status ?? undefined,
        vipStatus
      )
    )
      .pipe(
        map(x => new Blob([x.data], { type: 'text/csv' })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, `reservations_${new Date().valueOf()}.csv`));
  }

  getCheckInReservations({
    propertyId,
    firstName,
    recordNumber,
    recordNumberRange,
    lastName,
    crew,
    email,
    phone,
    start,
    end,
    status,
    corporateAccountName,
  }: Required<ReservationUIState, 'propertyId'>) {
    from(
      this.searchApi.searchCheckinGet(
        propertyId,
        recordNumber ?? undefined,
        recordNumberRange ?? undefined,
        firstName ?? undefined,
        lastName ?? undefined,
        crew ?? undefined,
        email ?? undefined,
        phone ?? undefined,
        corporateAccountName ?? undefined,
        undefined, //planName
        undefined, //isFilterByZeroCharges
        undefined, //createdByUserId
        undefined, //createdByUserType
        undefined, //roomTypeId
        start && lightFormat(start, 'yyyy-MM-dd'),
        end && lightFormat(end, 'yyyy-MM-dd'),
        status ?? undefined
      )
    )
      .pipe(
        map(response => response.data),
        map(({ data, ...page }) => ({
          data: reservationMapper(page)(data),
          ...page,
        }))
      )
      .subscribe(({ data }) =>
        applyTransaction(() => {
          this.store.upsertMany(data);
          this.updateUI({
            propertyId,
            recordNumber,
            start,
            end,
            firstName,
            lastName,
            crew,
            email,
            phone,
            status,
          });
        })
      );
  }

  getCurrentStays(
    filter: Pick<
      ReservationUIState,
      'recordNumber' | 'firstName' | 'lastName' | 'crew' | 'bookedBy' | 'corporateAccountId'
    >,
    page?: PaginatedQuery
  ): void {
    this.updateUI(filter);
    this.queryUserReservations({
      ...filter,
      status: ReservationStatus.CheckedIn,
      ...page,
    }).subscribe(({ data, ...pagination }) => {
      this.updatePaginationState(pagination, 'current');
    });
  }

  getUpcomingStays(
    filter: Pick<
      ReservationUIState,
      'recordNumber' | 'firstName' | 'lastName' | 'crew' | 'bookedBy' | 'corporateAccountId'
    >,
    page?: PaginatedQuery
  ): void {
    this.updateUI(filter);
    this.queryUserReservations({ ...filter, status: ReservationStatus.Open, ...page }).subscribe(
      ({ data, ...pagination }) => {
        this.updatePaginationState(pagination, 'future');
      }
    );
  }

  getKioskReservations(propertyId: Property['id']): void {
    combineLatest([
      this.queryUserReservations({ propertyId, status: ReservationStatus.Open, guestOnly: true }),
      this.queryUserReservations({
        propertyId,
        status: ReservationStatus.CheckedIn,
        guestOnly: true,
      }),
    ])
      .pipe(setLoading(this.store))
      .subscribe();
  }

  exportUserReservations({
    propertyId,
    guestOnly,
    recordNumber,
    firstName,
    lastName,
    crew,
    corporateAccountId,
    start,
    end,
    status,
  }: {
    propertyId?: string;
    guestOnly?: boolean;
    recordNumber?: number;
    firstName?: string;
    lastName?: string;
    crew?: string;
    corporateAccountId?: string;
    start?: string;
    end?: string;
    status?: ReservationStatus;
  }) {
    from(
      this.searchApi.searchUserExportGet(
        propertyId,
        guestOnly,
        recordNumber,
        firstName,
        lastName,
        crew,
        corporateAccountId,
        start,
        end,
        status
      )
    )
      .pipe(
        map(x => new Blob([x.data], { type: 'text/csv' })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, `reservations_${status}_${new Date().valueOf()}.csv`));
  }

  private queryUserReservations({
    propertyId,
    guestOnly,
    recordNumber,
    firstName,
    lastName,
    crew,
    corporateAccountId,
    start,
    end,
    status,
    continuationToken,
    limit,
  }: {
    propertyId?: string;
    guestOnly?: boolean;
    recordNumber?: number;
    firstName?: string;
    lastName?: string;
    crew?: string;
    corporateAccountId?: string;
    start?: string;
    end?: string;
    status?: ReservationStatus;
    continuationToken?: string | null;
    limit?: number;
  }) {
    return from(
      this.searchApi.searchUserGet(
        propertyId,
        guestOnly,
        recordNumber,
        firstName,
        lastName,
        crew,
        corporateAccountId,
        start,
        end,
        status,
        continuationToken ?? undefined,
        limit
      )
    ).pipe(
      map(response => response.data),
      map(({ data, ...page }) => ({
        data: reservationMapper(page)(data),
        ...page,
      })),
      tap(({ data }) => {
        applyTransaction(() => {
          this.store.upsertMany(data);
          this.updateUI({ propertyId });
        });
      })
    );
  }

  getGuestCurrentStays(
    propertyId: Property['id'],
    userId: GuestProfile['userId'],
    page?: PaginatedQuery
  ) {
    this.queryGuestReservations({
      propertyId,
      userId,
      status: ReservationStatus.CheckedIn,
      ...page,
    }).subscribe(({ data, ...pagination }) => {
      this.updatePaginationState(pagination, 'current');
    });
  }

  getGuestUpcomingStays(
    propertyId: Property['id'],
    userId: GuestProfile['userId'],
    page?: PaginatedQuery
  ) {
    this.queryGuestReservations({
      propertyId,
      userId,
      status: ReservationStatus.Open,
      ...page,
    }).subscribe(({ data, ...pagination }) => {
      this.updatePaginationState(pagination, 'future');
    });
  }

  exportGuestReservations({
    propertyId,
    userId,
    start,
    end,
    status,
  }: {
    propertyId: Property['id'];
    userId: GuestProfile['userId'];
    start?: string;
    end?: string;
    status?: ReservationStatus;
  }) {
    from(this.searchApi.searchGuestExportGet(userId, propertyId, start, end, status))
      .pipe(
        map(x => new Blob([x.data], { type: 'text/csv' })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, `guest_reservations_${status}_${new Date().valueOf()}.csv`));
  }

  private queryGuestReservations({
    propertyId,
    userId,
    start,
    end,
    status,
    continuationToken,
    limit,
  }: {
    propertyId: Property['id'];
    userId: GuestProfile['userId'];
    start?: string;
    end?: string;
    status?: ReservationStatus;
    continuationToken?: string | null;
    limit?: number;
  }) {
    return from(
      this.searchApi.searchGuestGet(
        userId,
        propertyId,
        start,
        end,
        status,
        continuationToken ?? undefined,
        limit
      )
    ).pipe(
      map(response => response.data),
      map(({ data, ...page }) => ({
        data: reservationMapper(page)(data),
        ...page,
      })),
      tap(({ data }) => {
        applyTransaction(() => {
          this.store.upsertMany(data);
          this.updateUI({ propertyId, userId });
        });
      })
    );
  }

  retrieveVerificationCode(reservationId: Reservation['id']) {
    this.store.update({ verificationCode: null });
    from(this.reservationApi.reservationClaimCodeGet(reservationId)).subscribe(({ data }) =>
      this.store.update({ verificationCode: data.code })
    );
  }

  sendVerificationCode(reservationId: Reservation['id']) {
    this.store.update({ verifiedGuest: null });
    from(this.reservationApi.reservationClaimSendPost(reservationId))
      .pipe(
        catchError((e: Error) => {
          if (isAxiosError(e) && e.response?.status === 400) {
            return throwError(new Error('Reservation does not have a contact for verification'));
          }

          return throwError(e);
        }),
        dispatchForm(CheckInForm.SendCode),
        catchError(() => EMPTY) // do not propogate error
      )
      .subscribe();
  }

  checkVerificationCode(reservationId: Reservation['id'], code: number) {
    this.store.update({ verifiedGuest: null });
    from(this.reservationApi.reservationClaimVerifyPost({ reservationId, code }))
      .pipe(
        catchError((e: Error) => {
          if (isAxiosError(e) && e.response?.status === 403) {
            return throwError(new Error('Invalid / expired verification code'));
          }

          return throwError(e);
        }),
        dispatchForm(ReservationForms.VerifyCode),
        catchError(() => EMPTY) // do not propogate error
      )
      .subscribe(({ data }) => {
        this.store.update({
          verifiedGuests: {
            guest: {
              contact: data.contact,
              hasAccount: data.hasAccount,
              userKey: data.userKey,
            } as VerifiedGuest,
            otherGuests:
              data.others?.map(
                m =>
                  ({
                    userId: m.userId,
                    name: m.name,
                    contact: m.contact,
                  } as ClaimGuestContactRequest)
              ) ?? [],
            skipVerification: this.claimRequiresVerification(data),
          },
        });
      });
  }

  private claimRequiresVerification(response: Reservations.VerifyGuestResponse) {
    if (
      response.hasAccount &&
      !!response.userKey &&
      !!response.contact?.contact.email &&
      !!response.contact?.contact.phone &&
      response.others?.every(
        s => s.name.first && s.name.last && (!!s.contact.email || !!s.contact.phone)
      )
    ) {
      return true;
    }
    return false;
  }

  updateVerifiedGuest(
    contact: Contact,
    name: Name,
    otherGuests: ClaimGuestContactRequest[],
    guestUserKey?: string
  ) {
    from(this.accountApi.accountExistsPost({ email: contact.email ?? '', userKey: guestUserKey }))
      .pipe(
        map(() => {
          return { contact, name, otherGuests };
        }),
        catchError(() =>
          // this API throws an error if the account does not exist.
          // it is being used for another purpose, or else this behavior would be changed.
          {
            throw new Error(`Invalid account information.`);
          }
        ),
        dispatchForm(ReservationForms.VerifyGuest),
        catchError(() => EMPTY) // do not propogate error
      )
      .subscribe(_verifiedGuests =>
        this.store.update(({ verifiedGuests }) => {
          return {
            verifiedGuests: {
              ...verifiedGuests,
              guest: {
                contact: {
                  contact: {
                    email: contact.email,
                    phone: getE164FormattedPhoneNumber(contact.phone),
                  },
                  name: {
                    first: name.first,
                    last: name.last,
                  },
                },
              },
              otherGuests: otherGuests.map(m => ({
                ...m,
                contact: {
                  email: m.contact?.email,
                  phone: getE164FormattedPhoneNumber(m.contact?.phone),
                },
              })),
            },
          };
        })
      );
  }

  claimReservation(
    reservationId: Reservation['id'],
    code: number,
    contact: Contact,
    name: Name,
    otherGuests: Reservations.ClaimGuestContactRequestModel[]
  ) {
    const pollUntilClaimed = defer(() =>
      this.reservationApi.reservationClaimVerifyPost({ reservationId, code })
    ).pipe(
      poll(1000),
      skipWhile(({ data }) => !data.hasAccount),
      take(1),
      timeout(10000)
    );

    from(
      this.reservationApi.reservationClaimPost({
        reservationId,
        code,
        contact,
        name,
        others: otherGuests,
      })
    )
      .pipe(
        switchMap(() => pollUntilClaimed),
        switchMap(async ({ data }) => {
          if (data.hasAccount && !!data.userKey) {
            await this.sessionService.loginWithUserKey(data.userKey);
          }

          return data;
        }),
        dispatchForm(ReservationForms.ClaimReservation)
      )
      .subscribe();
  }

  getReservationModificationOptions(reservation: Reservation) {
    from(this.reservationApi.reservationIdEditGet(reservation.id))
      .pipe(map(x => mapReservationModifications(x.data)))
      .subscribe(x => this.store.update(reservation.id, x));
  }

  GetReservationChangeOptions(reservation: Reservation, activityType: ReservationActivity) {
    from(this.reservationApi.reservationIdChangeActivityTypeGet(reservation.id, activityType))
      .pipe(map(x => mapReservationChangeOptions(x.data)))
      .subscribe(x => this.store.update(reservation.id, x));
  }

  getReservationChangeBillingOptions(reservationId: Reservation['id']) {
    from(this.reservationApi.reservationIdReservationBillingGet(reservationId))
      .pipe(map(x => mapReservationChangeBillingOptions(x.data)))
      .subscribe(x => this.store.update(reservationId, x));
  }

  getReservationPolicy(reservationId: Reservation['id']) {
    from(this.reservationApi.reservationIdPolicyGet(reservationId))
      .pipe(map(x => mapReservationPolicy(x.data.data)))
      .subscribe(x => {
        this.store.update(reservationId, x);
      });
  }

  updateReservation(
    reservation: Reservation,
    checkInDate: Date,
    checkOutDate: Date,
    rooms: Array<Reservations.UpdateReservationRoomModel>,
    billing: Reservations.CreateBillingModel,
    _savePaymentMethod: boolean,
    paymentProfileId: string,
    card?: AcceptCardData,
    overrideCode?: number
  ) {
    getPaymentNonce(card)
      .pipe(
        switchMap(nonce =>
          this.reservationApi.reservationIdPost(reservation.id, {
            checkInDate: lightFormat(checkInDate, 'yyyy-MM-dd'),
            checkOutDate: lightFormat(checkOutDate, 'yyyy-MM-dd'),
            rooms,
            billing,
            paymentNonce: nonce?.opaqueData,
            savePaymentMethod: true, //Hardcoding to true
            paymentProfileId,
            overrideCode,
            ...formatPaymentMethod(paymentProfileId),
          })
        ),
        dispatchForm(ReservationForms.UpdateReservation),
        map(response => reservationMapper()(response.data.data))
      )
      .subscribe(res => {
        this.store.upsert(res.id, res);
      });
  }

  previewRebookReservation(
    reservationId: Reservation['id'],
    checkInDate: Date,
    checkOutDate: Date,
    quantity: number
  ) {
    from(
      this.reservationApi.reservationRebookPreviewIdPost(reservationId, {
        checkInDate: lightFormat(checkInDate, 'yyyy-MM-dd'),
        checkOutDate: lightFormat(checkOutDate, 'yyyy-MM-dd'),
        quantity,
      })
    )
      .pipe(map(x => mapRebookPreviewModel(x.data)))
      .subscribe(x => this.store.update(reservationId, x));
  }

  rebookReservation(
    reservation: Reservation,
    checkInDate: Date,
    checkOutDate: Date,
    rooms: Array<Reservations.CreateReservationRoomModel>,
    billing: Reservations.CreateBillingModel,
    _savePaymentMethod: boolean,
    paymentProfileId: string,
    createForGuest: boolean,
    rebookOnly: boolean,
    card?: AcceptCardData,
    overrideCode?: number
  ) {
    const validate$ = from(
      this.reservationApi.reservationValidatePost({
        propertyId: reservation.propertyId,
        checkInDate: formatISO(checkInDate, { representation: 'date' }),
        checkOutDate: formatISO(checkOutDate, { representation: 'date' }),
        corporateAccountId: reservation.affiliation?.corporateAccountId,
        guests: getGuests(reservation)?.map(m => ({
          ...m,
          roomGroup: m.roomGroup ?? undefined,
        })),
        reservationId: reservation.id,
      } as Reservations.ValidateBookingRequest)
    ).pipe(
      map(results => results.data),
      tap(duplicate => {
        if (!duplicate.isValid)
          throw new Error(duplicate.message ?? 'Duplicate reservations were found.');
      })
    );

    const obs$ = combineLatest([
      defer(() => getPaymentNonce(card)),
      defer(() => {
        if (!rebookOnly) {
          return this.reservationApi.reservationCancelPost({
            reservationId: reservation.id,
            overrideCode,
          });
        } else {
          return new Promise(resolve => {
            resolve(undefined);
          });
        }
      }),
    ]).pipe(
      take(1), // Add this line &&
      distinctUntilChanged(), // Add this line too
      switchMap(([nonce, _]) =>
        this.reservationApi.reservationRebookIdPost(reservation.id, {
          checkInDate: lightFormat(checkInDate, 'yyyy-MM-dd'),
          checkOutDate: lightFormat(checkOutDate, 'yyyy-MM-dd'),
          rooms,
          billing,
          paymentNonce: nonce?.opaqueData,
          savePaymentMethod: true, //Hardcoding to true
          paymentProfileId,
          createForGuest,
          rebookOnly,
          overrideCode,
          ...formatPaymentMethod(paymentProfileId),
        })
      ),
      map(response => response.data)
    );

    validate$
      .pipe(
        switchMap(_ => obs$),
        dispatchForm(ReservationForms.RebookReservation)
      )
      .subscribe(data =>
        applyTransaction(() => {
          dispatchForm(ReservationForms.CancelReservation);
          this.store.upsert(reservation.id, reservationMapper()(data.cancelledReservation));
          this.store.add(reservationMapper()(data.newReservation));
        })
      );
  }

  changeCostCenterFields(
    reservationId: Reservation['id'],
    reservationChangeCostCenterFieldsRequest: ReservationChangeCostCenterFields,
    uniqueFormName: string
  ) {
    from(
      this.reservationApi.reservationIdChangecostcenterfieldsPost(
        reservationId,
        reservationChangeCostCenterFieldsRequest
      )
    )
      .pipe(dispatchForm(uniqueFormName))
      .pipe(dispatchForm(ReservationForms.UpdateCostCenterFields))
      .subscribe(({ data }) => {
        applyTransaction(() => {
          this.store.update(data.data.id, data.data);
        });
      });
  }

  updateSleepSchedule(reservation: Reservation, sleepSchedule: SleepSchedule, formName: string) {
    from(this.reservationApi.reservationIdSleepSchedulePut(reservation.id, { sleepSchedule }))
      .pipe(
        dispatchForm(formName),
        map(x => x.data.data)
      )
      .subscribe(x => this.store.upsert(x.id, x));
  }

  cancelReservation(reservation: Reservation, overrideCode?: number) {
    from(this.reservationApi.reservationCancelPost({ reservationId: reservation.id, overrideCode }))
      .pipe(dispatchForm(ReservationForms.CancelReservation))
      .subscribe(({ data }) => {
        applyTransaction(() => {
          this.store.update(reservation.id, { status: Reservations.ReservationStatus.Canceled });
          this.store.update({
            lastActivity: {
              activity: ReservationActivity.CancelReservation,
              cost: mapUpdateCost(data.data),
            },
          });
        });

        this.getReservationById(reservation.id);
      });
  }

  checkOutReservation(reservation: Reservation, overrideCode?: number) {
    from(
      this.reservationApi.reservationCheckoutPost({ reservationId: reservation.id, overrideCode })
    )
      .pipe(dispatchForm(ReservationForms.CheckOutReservation))
      .subscribe(({ data }) => {
        applyTransaction(() => {
          this.store.update(reservation.id, { status: Reservations.ReservationStatus.CheckedOut });
          this.store.update({
            lastActivity: {
              activity: ReservationActivity.CheckOut,
              cost: mapUpdateCost(data.data),
            },
          });
        });

        this.getReservationById(reservation.id);
      });
  }

  getLateCheckOutFee(reservationId: Reservation['id']) {
    from(this.reservationApi.reservationIdLateCheckoutGet(reservationId))
      .pipe(map(x => x.data.data))
      .subscribe(lateCheckOutFee => this.store.update(reservationId, { lateCheckOutFee }));
  }

  refundCharge(reservationId: Reservation['id'], model: RefundReservationChargeModel) {
    from(this.reservationApi.reservationIdRefundPost(reservationId, model))
      .pipe(
        dispatchForm(ReservationForms.RefundReservationCharge),
        map(x => x.data)
      )
      .subscribe(refund => this.store.update(refund.data.id, reservationMapper()(refund.data)));
  }

  refundStayDate(reservationId: Reservation['id'], model: ReservationRoomRateModel, notes: string) {
    from(
      this.reservationApi.reservationIdRefundStayPost(reservationId, {
        date: model.date,
        reservationRoomId: model.reservationRoomId,
        notes: notes,
      })
    )
      .pipe(
        dispatchForm(ReservationForms.RefundStayDate),
        map(x => x.data)
      )
      .subscribe(roomRate => this.store.update(reservationId, reservationMapper()(roomRate.data)));
  }

  purchaseLateCheckOut(
    reservation: Reservation,
    billing?: Reservations.CreateBillingModel,
    _savePaymentMethod?: boolean,
    paymentProfileId?: string,
    card?: AcceptCardData,
    overrideCode?: number
  ) {
    getPaymentNonce(card)
      .pipe(
        switchMap(nonce =>
          this.reservationApi.reservationIdLateCheckoutPost(reservation.id, {
            billing,
            savePaymentMethod: true, //Hardcoding to true
            paymentProfileId,
            paymentNonce: nonce?.opaqueData,
            overrideCode,
            ...formatPaymentMethod(paymentProfileId),
          })
        ),
        dispatchForm(ReservationForms.LateCheckOut)
      )
      .subscribe(({ data }) => {
        this.store.upsert(reservation.id, reservationMapper()(data));
      });
  }

  changeBilling(
    reservation: Reservation,
    rooms: Array<Reservations.UpdateReservationRoomModel>,
    billing: Reservations.CreateBillingModel,
    _savePaymentMethod: boolean,
    paymentProfileId: string,
    flexRate: boolean,
    dailyHousekeeping: boolean,
    card?: AcceptCardData,
    amenities?: Array<string> | null,
    promoCode?: string | null,
    corporateAccountId?: string | null,
    delegateUserId?: string | null
  ) {
    getPaymentNonce(card)
      .pipe(
        switchMap(nonce =>
          this.reservationApi.reservationIdReservationBillingPost(reservation.id, {
            rooms,
            billing,
            paymentNonce: nonce?.opaqueData,
            savePaymentMethod: true, //Hardcoding to true
            paymentProfileId,
            ...formatPaymentMethod(paymentProfileId),
            amenities,
            flexRate,
            dailyHousekeeping,
            promoCode: promoCode ?? undefined,
            corporateAccountId: (corporateAccountId === '' ? null : corporateAccountId) ?? null,
            delegateUserId: (delegateUserId === '' ? null : delegateUserId) ?? null,
          })
        ),
        dispatchForm(ReservationForms.ChangeBilling),
        map(response => reservationMapper()(response.data.data))
      )
      .subscribe(res => {
        this.store.upsert(res.id, res);
      });
  }

  selectReservation(id?: Reservation['id'] | null): () => void {
    const prior = this.store.getValue().active;

    applyTransaction(() => {
      this.store.setActive(id ?? null);
      this.resetActivity();
    });

    return () => this.selectReservation(prior);
  }

  resetBooking() {
    this.store.update(() => ({
      bookingStatus: null,
    }));
  }

  private resetActivity() {
    this.store.update({ lastActivity: null });
  }

  private updateUI(state: Partial<ReservationUIState>) {
    this.store.update(({ ui }) => ({
      ui: { ...ui, ...state },
    }));
  }

  private updatePaginationState(
    { isDone, continuationToken }: PaginationState,
    key: 'pagination' | 'current' | 'future' = 'pagination'
  ) {
    this.store.update(() => ({
      [key]: { isDone, continuationToken },
    }));
  }
}

export const reservationService = new ReservationService(
  reservationStore,
  sessionService,
  new Reservations.ReservationApi(),
  new Reservations.ReservationSearchApi(),
  new Auth.AccountApi()
);
