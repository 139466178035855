import React, { useEffect } from 'react';
import { FormStatus, sessionQuery } from '@lib/state';
import { useObservable, Feedback, ErrorDisplay, FormTextField, SubmitButton } from '@lib/common';
import { endOfMonth, startOfMonth } from 'date-fns';
import { formatDateMidnight } from 'app/shared';
import { Grid } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useFormContext } from 'react-hook-form';

interface Props {
  status: FormStatus;
  error?: Error;
  resetFormUI: () => void;
}

export const UnbilledReservationsReport: React.FC<Props> = ({
  status,
  resetFormUI,
  error,
}: Props) => {
  const { register, watch, setValue } = useFormContext();
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const accountingNotifications = useObservable(sessionQuery.accountingNotifications);

  useEffect(() => {
    const currentDate = new Date();
    register({ name: 'startDate' }, { required: true });
    register({ name: 'endDate' }, { required: true });

    setValue('startDate', startOfMonth(currentDate));
    setValue('endDate', endOfMonth(currentDate));
  }, [register, setValue]);

  useEffect(() => {
    if (accountingNotifications) setValue('recipients', accountingNotifications);
  }, [accountingNotifications, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={date => {
            if (date) setValue('startDate', formatDateMidnight(date));
          }}
          format="MM/dd/yyyy"
          autoOk
        />
      </Grid>
      <Grid item xs={2}>
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={date => {
            if (date) setValue('endDate', formatDateMidnight(date));
          }}
          format="MM/dd/yyyy"
          autoOk
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextField label="Receipents" type="text" name="recipients" required fullWidth />
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item>
          <Feedback show={status === FormStatus.Success} severity="success" onHide={resetFormUI}>
            Unbilled Reservation Report Requested
          </Feedback>
          <ErrorDisplay error={error} />
          <SubmitButton
            type="submit"
            color="secondary"
            variant="contained"
            disabled={status === FormStatus.Pending}
          >
            Send Report
          </SubmitButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
