import React, { useCallback, useEffect } from 'react';
import { FormHub, LoadingIndicator, SubmitButton, useFormEvents, useObservable } from '@lib/common';
import {
  AccessControlType,
  ArmDeviceProvisioningPolicyType,
  FormStatus,
  propertyQuery,
  propertyService,
} from '@lib/state';
import {
  DeviceProvisioningQRCodeForm,
  DeviceProvisioningQRCodeFormFields,
} from './device-provisioning-qrcode.form';
import { Grid } from '@material-ui/core';
import { DeviceEnrollmentQRCodeComponent } from './device-enrollment-qrcode.component';
import { PropertyForms } from '@lib/state/property/property.forms';
import { AreaFilters, areaQuery, areaService } from 'app/state';

interface Props {}

export const DeviceProvisioningQRCodeComponent: React.FC<Props> = () => {
  const [{ status }] = useFormEvents(PropertyForms.GetDeviceQRCode);
  const enrollmentToken = useObservable(propertyQuery.enrollmentToken);
  const property = useObservable(propertyQuery.activeProperty);
  const areas = useObservable(areaQuery.areas);

  useEffect(() => {
    if (property?.id) areaService.getAreas(property.id, { name: '' } as AreaFilters);
  }, [property]);

  const submit = useCallback(
    ({
      devicePolicyType,
      ssid,
      wiFiPassword,
      deviceName,
      deviceType,
      areaId,
    }: DeviceProvisioningQRCodeFormFields) => {
      if (property) {
        if (devicePolicyType !== ArmDeviceProvisioningPolicyType.AccessControlQRCodeScanner)
          propertyService.getDeviceProvisioningQRCodeData(property?.id, devicePolicyType);
        else {
          propertyService.getQRCodeScannerQRCodeData(
            property?.id,
            ssid,
            wiFiPassword,
            areas?.filter(x => x.id === areaId)[0]?.name ?? deviceName,
            deviceType,
            areaId
          );
        }
      }
    },
    [property, areas]
  );

  return (
    <FormHub<DeviceProvisioningQRCodeFormFields>
      formOptions={{
        defaultValues: {
          devicePolicyType: ArmDeviceProvisioningPolicyType.AccessControl,
          ssid: '',
          wiFiPassword: '',
          deviceName: '',
          deviceType: AccessControlType.RoomDoorLock,
        },
      }}
      onSubmit={submit}
    >
      <DeviceProvisioningQRCodeForm areas={areas} />
      <Grid item xs={12}>
        <SubmitButton
          variant="contained"
          color="secondary"
          fullWidth
          pending={status === FormStatus.Pending}
          disabled={status === FormStatus.Pending}
        >
          Generate QR Code
        </SubmitButton>
        {status === FormStatus.Pending && (
          <LoadingIndicator loadingText="Generating QR Code" fillContainer />
        )}
        {!!enrollmentToken ? (
          <DeviceEnrollmentQRCodeComponent enrollmentToken={enrollmentToken} />
        ) : null}
      </Grid>
    </FormHub>
  );
};
