import React from 'react';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import { AccessControlType, ArmDeviceProvisioningPolicyType } from '@lib/state';
import { EnumValueDisplay, FormSelect, FormTextField, Section } from '@lib/common';
import { FormDevicePolicyField } from './form-fields/form-device-policy-field';
import { useFormContext } from 'react-hook-form';
import { Area } from 'app/state';
import { Link } from 'react-router-dom';

export interface DeviceProvisioningQRCodeFormFields {
  devicePolicyType: ArmDeviceProvisioningPolicyType;
  deviceName: string;
  doorType: AccessControlType;
  deviceType: AccessControlType;
  ssid: string;
  wiFiPassword: string;
  areaId?: string;
}

interface Props {
  devicePolicyType?: ArmDeviceProvisioningPolicyType | null;
  deviceName?: string;
  doorType?: AccessControlType;
  ssid?: string;
  password?: string;
  areas: Area[];
}
export const DeviceProvisioningQRCodeForm: React.FC<Props> = ({
  devicePolicyType,
  deviceName,
  doorType,
  ssid,
  password,
  areas,
}) => {
  const { watch } = useFormContext();
  const type = watch('devicePolicyType');
  const deviceType = watch('deviceType');

  return (
    <Section title="Device Enrollment QR Code">
      <Typography
        variant="body1"
        style={{
          marginBottom: '2rem',
          color: '#7c7c7c',
        }}
      >
        Select the type of device you wish to generate a Device Enrollment QR Code for.
      </Typography>
      <Grid item xs={12} style={{ marginBottom: '1rem' }}>
        <FormDevicePolicyField
          name={'devicePolicyType'}
          required={false}
          fullWidth
          defaultValue={devicePolicyType}
        />
      </Grid>
      {type === ArmDeviceProvisioningPolicyType.AccessControlQRCodeScanner && (
        <>
          <Grid item xs={12}>
            <FormSelect name="deviceType" label="Device Type" fullWidth defaultValue={doorType}>
              {Object.values(AccessControlType).map(x => (
                <MenuItem key={x} value={x}>
                  <EnumValueDisplay value={x} />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          {deviceType === AccessControlType.RoomDoorLock ? (
            <Grid item xs={12}>
              <FormTextField
                name="deviceName"
                label="Device Name"
                defaultValue={deviceName}
                fullWidth
                inputProps={{ pattern: '([A-Za-z0-9-_;])*' }}
                helperText="Letters, numbers, hyphens(-), colons(;) and underscores(_)."
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              {areas.length === 0 ? (
                <Typography>
                  Please add an area before proceeding.<Link to="/areas">Go to Common Areas</Link>
                </Typography>
              ) : (
                <FormSelect name="areaId" label="Select Area" fullWidth required>
                  {areas.map(area => (
                    <MenuItem key={area.id} value={area.id}>
                      {area.name}
                    </MenuItem>
                  ))}
                </FormSelect>
              )}
            </Grid>
          )}
          <Grid item container xs={12} direction="row" style={{ marginBottom: '1rem' }}>
            <Grid item xs={6}>
              <FormTextField
                name="ssid"
                label="WiFi SSID"
                defaultValue={ssid}
                fullWidth
                style={{ paddingRight: '.25rem' }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                name="wiFiPassword"
                label="WiFi Password"
                defaultValue={password}
                fullWidth
                style={{ paddingLeft: '.25rem' }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Section>
  );
};
