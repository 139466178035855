import { StoreConfig, EntityStore, EntityState, ActiveState } from '@datorama/akita';
import { CorporateAccount } from '..';
import { ChangeBillingRefunds } from '../api/generated/reservations/models/change-billing-refunds';

import { Property, PropertyPolicy } from '../property';
import { UpdateCost } from '../reservation';
import { RoomRate } from './room-rate.model';

export interface RoomRateUIState {
  propertyId?: Property['id'];
  checkInDate?: Date;
  checkOutDate?: Date;
  roomTypeId?: string;
  quantity?: number;
  promoCode?: string | null;
  guestsPerRoom?: number;
}

export interface RoomRateState extends EntityState<RoomRate, RoomRate['roomTypeId']>, ActiveState {
  ui: RoomRateUIState;
  updateCost?: UpdateCost;
  corporateBooking?: CorporateAccount;
  changeBillingRefunds?: ChangeBillingRefunds;
  policy?: PropertyPolicy;
}

@StoreConfig({ name: 'room-rates', resettable: true, idKey: 'roomTypeId' })
export class RoomRateStore extends EntityStore<RoomRateState> {
  constructor() {
    super({ active: null, ui: {} });
  }
}

export const roomRateStore = new RoomRateStore();
