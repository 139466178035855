import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { GuestLookupFields } from '..';
import { GuestLookupProfile } from './guest-lookup.model';

export interface GuestLookupState
  extends EntityState<GuestLookupProfile, GuestLookupProfile['key']> {
  ui: GuestLookupFields;
}

@StoreConfig({ name: 'guest-lookup', resettable: true, idKey: 'key' })
export class GuestLookupStore extends EntityStore<GuestLookupState> {
  constructor() {
    super({
      ui: {
        hasEmployeeRole: false,
        isDelegate: false,
      },
    });
  }
}

export const guestLookupStore = new GuestLookupStore();
