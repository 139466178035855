import React, { useCallback, useEffect } from 'react';
import { Container } from '@material-ui/core';

import { propertyQuery, roomTypeService } from '@lib/state';
import {
  useObservable,
  Section,
  usePageTitle,
  LoadingIndicator,
  usePropertyRoomTypes,
  usePropertyAmenities,
} from '@lib/common';
import {
  roomRatePlanService,
  roomRatePlanQuery,
  rateRestrictionService,
  rateRestrictionQuery,
  RateRestriction,
  corporateAccountQuery,
  corporateAccountService,
} from 'app/state';
import {
  RatePlanExportFilterFields,
  RatePlanFilterFields,
  RatePlanFilters,
  RatePlanList,
  RateRestrictionsSection,
} from '../components';

export const RoomRatePlanListPage: React.FC = () => {
  usePageTitle('Room Rate Plans');

  const propertyId = useObservable(propertyQuery.activeProperty, 'async')?.id;
  const customerId = useObservable(propertyQuery.activeProperty)?.customerId;
  const roomTypes = usePropertyRoomTypes(propertyId);
  const roomRatePlans = useObservable(roomRatePlanQuery.ratePlans);
  const rateRestrictions = useObservable(rateRestrictionQuery.rateRestrictions);
  const propertyAmenities = usePropertyAmenities(propertyId);
  const corporations = useObservable(corporateAccountQuery.corporations);
  const ratePlanfilters = useObservable(roomRatePlanQuery.filters);

  useEffect(() => {
    if (customerId) corporateAccountService.getAllCorporateAccounts(customerId);
  }, [customerId]);

  const onFiltersChange = useCallback(
    ({ roomTypeId, corporateAccountId, showDisabled, ratePlanSort }: RatePlanFilterFields) => {
      if (!propertyId) return;

      roomTypeService.selectRoomType(roomTypeId);
      roomRatePlanService.getRatePlans({
        propertyId,
        roomTypeId,
        showDisabled,
        corporateAccountId,
        ratePlanSort,
      });
      rateRestrictionService.getRateRestrictions(propertyId, roomTypeId ?? roomTypes[0]?.id);
    },
    // eslint-disable-next-line
    [propertyId]
  );

  const onExport = useCallback(
    ({
      roomTypeId,
      corporateAccountId,
      showDisabled,
      roomTypeName,
      ratePlanSort,
    }: RatePlanExportFilterFields) => {
      if (!propertyId) return;
      roomRatePlanService.exportRatePlans({
        propertyId,
        roomTypeId,
        showDisabled,
        corporateAccountId,
        roomTypeName,
        ratePlanSort,
      });
    },
    // eslint-disable-next-line
    [propertyId]
  );

  const onDeleteRateRestriction = useCallback((rateRestriction: RateRestriction) => {
    rateRestrictionService.deleteRateRestriction(rateRestriction);
  }, []);

  if (!propertyId) return null;

  if (!roomTypes.length || !propertyAmenities) return <LoadingIndicator />;

  return (
    <Container maxWidth="xl">
      <RatePlanFilters
        roomTypes={roomTypes}
        onChange={onFiltersChange}
        onExport={onExport}
        accounts={corporations}
        filters={{ ...ratePlanfilters }}
      />
      <Section title="Room Rate Plans" textAlign="left" disableGutters>
        <RatePlanList
          plans={roomRatePlans}
          roomTypes={roomTypes}
          propertyAmenities={propertyAmenities}
        />
      </Section>
      <RateRestrictionsSection
        rateRestrictions={rateRestrictions}
        onDeleteRateRestriction={onDeleteRateRestriction}
      />
    </Container>
  );
};
