import {
  AppColors,
  DataTable,
  EnumValueDisplay,
  IDataTableColumn,
  Loading,
  RemoveButton,
  Section,
  useFormEvents,
} from '@lib/common';
import { Box, Button, Typography, createStyles, makeStyles } from '@material-ui/core';
import { CorporateContactForms, CorporateContactModel, corporateContactService } from 'app/state';
import React, { useCallback, useEffect, useState } from 'react';
import CorporateContactModal from './corporate-contact-modal';
import { CorporateAccount, FormStatus } from '@lib/state';
import { CorporateContactType } from '@lib/state/api/generated/reservations';

interface Props {
  contacts: Array<CorporateContactModel>;
  loading: boolean;
  account: CorporateAccount;
}

const useStyles = makeStyles(
  createStyles({
    button: {
      color: AppColors.LightBlue,
    },
  })
);

export default function CorporateContactList({ contacts, loading, account }: Readonly<Props>) {
  const styles = useStyles();
  const [contactFormOpen, setContactFormOpen] = useState(false);
  const [{ status: removeFormStatus, error: removeFormError }, resetRemoveUI] = useFormEvents(
    CorporateContactForms.Remove
  );
  const [{ status: contactFormStatus, error: contactFormError }, resetUI] = useFormEvents(
    CorporateContactForms.CreateOrEdit
  );
  const [contact, setContact] = useState<CorporateContactModel>();

  const onOpen = useCallback(
    (c?: CorporateContactModel) => {
      setContact(c);
      resetUI();
      setContactFormOpen(true);
    },
    [resetUI]
  );

  useEffect(() => {
    if (removeFormStatus === FormStatus.Success) resetRemoveUI();
  }, [removeFormStatus, resetRemoveUI]);

  const onContactFormClose = useCallback(() => {
    setContact(undefined);
    setContactFormOpen(false);
    resetUI();
  }, [resetUI, setContact, setContactFormOpen]);

  useEffect(() => {
    if (contactFormStatus === FormStatus.Success) {
      onContactFormClose();
    }
  }, [contactFormStatus, onContactFormClose]);

  const onDeleteConfirm = useCallback(
    (x: CorporateContactModel) => {
      if (x.id) corporateContactService.deleteContact(account.id, x.id);
    },
    [account.id]
  );

  const renderCell = (value?: string | null) => <Typography>{value}</Typography>;

  const renderRemoveButton = (contact: CorporateContactModel) => (
    <RemoveButton
      onConfirm={() => onDeleteConfirm(contact)}
      status={removeFormStatus}
      error={removeFormError}
      confirmView={
        <Typography>
          Are you sure you want to remove the {contact.corporateContactType} contact?
        </Typography>
      }
    />
  );

  const renderEditButton = (contact: CorporateContactModel) => (
    <Button className={styles.button} variant="outlined" onClick={() => onOpen(contact)}>
      Edit
    </Button>
  );

  const columns: Array<IDataTableColumn<CorporateContactModel>> = [
    {
      title: 'First',
      colSpan: 2,
      valueFactory: contact => renderCell(contact.name?.first ?? ''),
    },
    {
      title: 'Last',
      colSpan: 2,
      valueFactory: contact => renderCell(contact.name?.last ?? ''),
    },
    {
      title: 'Email',
      colSpan: 3,
      valueFactory: contact =>
        renderCell(contact.contact?.email ?? contact.contact?.alternateEmail),
    },
    {
      title: 'Phone',
      colSpan: 3,
      valueFactory: contact => renderCell(contact.contact?.phone),
    },
    {
      title: 'Contact Type',
      colSpan: 3,
      valueFactory: contact => {
        if (contact.corporateContactType === CorporateContactType.Other) {
          return renderCell(`${contact.corporateContactType}-${contact?.otherContactType}`);
        }
        return <EnumValueDisplay value={contact.corporateContactType} />;
      },
    },
    {
      title: '',
      align: 'right',
      valueFactory: contact => renderRemoveButton(contact),
    },
    {
      title: '',
      align: 'right',
      valueFactory: contact => renderEditButton(contact),
    },
  ];

  return (
    <Section title={`Corporate Contacts`} textAlign="left">
      <CorporateContactModal
        contact={contact}
        account={account}
        open={contactFormOpen}
        onClose={onContactFormClose}
        status={contactFormStatus}
        error={contactFormError}
      />
      <Box my="1rem" display="flex" justifyContent="flex-end" flexDirection="row">
        <Button color="secondary" variant="contained" onClick={() => onOpen()}>
          Add Contact
        </Button>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <DataTable items={contacts} columns={columns} getItemKey={contact => contact.id ?? ''} />
      )}
    </Section>
  );
}
