import React from 'react';

import { GuestRelationTypeEnum, GuestRelationTypeMap } from '@lib/state';
import { FormSelect, useFormPrefix } from '../../forms';
import { MenuItem } from '@material-ui/core';
import { GuestRelationDisplay } from '../../components';

interface Props {
  prefix?: string;
  relation?: GuestRelationTypeEnum;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}

export const GuestRelationField: React.FC<Props> = ({
  prefix,
  relation,
  required = false,
  readOnly = false,
  disabled,
}) => {
  const fieldName = useFormPrefix(prefix);

  const relationTypes = Array.from(GuestRelationTypeMap, ([name, value]) => ({
    name,
    value,
  }));

  if (readOnly) return <GuestRelationDisplay relation={relation} />;

  return (
    <FormSelect
      name={fieldName('relationType')}
      label="Guest Relationship"
      fullWidth
      defaultValue={relation ?? ''}
      required={required}
      disabled={disabled}
    >
      {relationTypes.map(x => (
        <MenuItem key={x.name} value={x.name}>
          {x.value}
        </MenuItem>
      ))}
      <MenuItem value="">Unknown</MenuItem>
    </FormSelect>
  );
};
