import { FormHub, StyledDialog } from '@lib/common';
import { CorporateAccount, FormStatus } from '@lib/state';
import { DialogContent } from '@material-ui/core';
import {
  CorporateContactModel,
  CreateCorporateContactRequest,
  UpdateCorporateContactRequest,
  corporateContactService,
} from 'app/state';
import React, { useCallback, useMemo } from 'react';
import CorporateContactForm from './corporate-contact-form';
import { CorporateContactType } from '@lib/state/api/generated/reservations';

interface Props {
  contact?: CorporateContactModel;
  account: CorporateAccount;
  open: boolean;
  onClose: () => void;
  status: FormStatus;
  error?: Error;
}

export default function CorporateContactModal({
  contact,
  account,
  onClose,
  open,
  status,
  error,
}: Readonly<Props>) {
  const defaultValues = useMemo(
    () => ({
      name: {
        first: contact?.name?.first ?? '',
        last: contact?.name?.last ?? '',
      },
      contact: {
        email: contact?.contact?.email ?? undefined,
        phone: contact?.contact?.phone ?? undefined,
      },
      corporateContactType:
        CorporateContactType[contact?.corporateContactType as keyof typeof CorporateContactType] ??
        undefined,
      otherContactType: contact?.otherContactType ?? '',
      notes: contact?.notes ?? '',
    }),
    [contact]
  );

  const onSubmit = useCallback(
    (c: CorporateContactModel) => {
      if (contact) {
        const request: UpdateCorporateContactRequest = { ...c };
        request.id = contact.id;
        corporateContactService.updateContact(account.id, request);
      } else {
        const request: CreateCorporateContactRequest = { ...c };
        request.corporateAccountId = account.id;
        corporateContactService.createContact(request);
      }
    },
    [contact, account]
  );

  return (
    <StyledDialog
      title={`${contact ? 'Edit' : 'Add'} Contact`}
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        <FormHub onSubmit={onSubmit} formOptions={{ defaultValues }}>
          <CorporateContactForm contact={contact} status={status} error={error} />
        </FormHub>
      </DialogContent>
    </StyledDialog>
  );
}
