import React, { useMemo } from 'react';
import { Paper, Grid, Typography, Box } from '@material-ui/core';

import { Reservation } from '@lib/state';
import {
  CostCenterFieldEditableDisplay,
  HousekeepingPolicyDisplay,
  SleepScheduleEditableDisplay,
  useCommonStyle,
} from '@lib/common';

interface Props {
  reservation: Reservation;
}

export const ReservationInfoSpecialRequests: React.FC<Props> = ({ reservation }) => {
  const styles = useCommonStyle();

  const amenities = useMemo(
    () =>
      reservation.amenities
        .map(x => `${x.name}: $${reservation.amenityFees?.find(f => f.code === x.code)?.fee}`)
        .join(', '),
    [reservation]
  );

  const nights = useMemo(() => {
    const rooms = reservation.rooms.flatMap(r => r.rates);

    return {
      refundedNights: rooms.filter(r => r.refunded).length,
      nonRefundedNights: rooms.filter(r => !r.refunded).length,
    };
  }, [reservation]);

  return (
    <Box height="100%" clone>
      <Paper className={styles.paper} elevation={3} square>
        <Typography variant="h5" paragraph>
          Details
        </Typography>
        {reservation.fields.length > 0 && (
          <Grid container spacing={1}>
            {reservation.fields.map(field => (
              <Grid key={field.key} item xs={6} sm={4} md={3}>
                <CostCenterFieldEditableDisplay reservation={reservation} costCenterField={field} />
              </Grid>
            ))}
          </Grid>
        )}
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Typography>
              <strong>Housekeeping</strong>
            </Typography>
            <Typography>
              <HousekeepingPolicyDisplay {...reservation.housekeepingPolicy} />
            </Typography>
          </Grid>
          {reservation.affiliation?.sleepSchedule && (
            <Grid item xs={3}>
              <SleepScheduleEditableDisplay reservation={reservation} />
            </Grid>
          )}
          <Grid item xs={3}>
            <Typography>
              <strong>Booking Flexibility</strong>
            </Typography>
            <Typography>
              {reservation.changeable && reservation.cancelable ? 'Yes' : 'No'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>Value Add &amp; Amenities</strong>
            </Typography>
            <Typography>{amenities || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>Special Requests</strong>
            </Typography>
            <Typography>{reservation.specialRequests || 'N/A'}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={2}>
              <Typography>
                <strong>Number of Nights</strong>
              </Typography>
              <Typography>{reservation.lengthOfStay}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                <strong>Non-refunded Nights</strong>
              </Typography>
              <Typography>{nights.nonRefundedNights}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                <strong>Refunded Nights</strong>
              </Typography>
              <Typography>{nights.refundedNights}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
