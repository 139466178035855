import { Reservations, dispatchForm, getE164FormattedPhoneNumber } from '@lib/state';
import { CorporateContactStore, corporateContactStore } from './corporate-contact.store';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { applyTransaction, setLoading } from '@datorama/akita';
import { CorporateContactForms } from './corproate-contact.forms';
import {
  CreateCorporateContactRequest,
  UpdateCorporateContactRequest,
} from './corporate-contact.model';

export class CorporateContactService {
  constructor(
    private readonly store: CorporateContactStore,
    private readonly api: Reservations.CorporateContactApi
  ) {}

  public createContact(contact: CreateCorporateContactRequest) {
    if (contact?.contact?.phone) {
      contact.contact.phone = getE164FormattedPhoneNumber(contact.contact.phone);
    }

    from(this.api.corporatecontactCorporateAccountIdPut(contact.corporateAccountId ?? '', contact))
      .pipe(
        map(data => data.data.data),
        setLoading(this.store),
        dispatchForm(CorporateContactForms.CreateOrEdit)
      )
      .subscribe(data => {
        applyTransaction(() => this.store.upsert(data.id, data));
      });
  }

  public updateContact(corporateAccountId: string, contact: UpdateCorporateContactRequest) {
    if (contact?.contact?.phone) {
      contact.contact.phone = getE164FormattedPhoneNumber(contact.contact.phone);
    }

    if (!contact.name) {
      contact.name = { first: '', last: '' };
    }
    contact.name.first = contact.name.first ?? '';
    contact.name.last = contact.name.last ?? '';

    from(this.api.corporatecontactCorporateAccountIdPost(corporateAccountId, contact))
      .pipe(
        map(data => data.data.data),
        setLoading(this.store),
        dispatchForm(CorporateContactForms.CreateOrEdit)
      )
      .subscribe(data => applyTransaction(() => this.store.update(data.id, data)));
  }

  public getContactsByAccountId(id: string) {
    from(this.api.corporatecontactCorporateAccountIdGet(id))
      .pipe(
        map(data => data.data.data),
        setLoading(this.store)
      )
      .subscribe(data => applyTransaction(() => this.store.upsertMany(data)));
  }

  public deleteContact(corporateAccountId: string, id: string) {
    from(this.api.corporatecontactCorporateAccountIdContactIdDelete(id, corporateAccountId))
      .pipe(setLoading(this.store), dispatchForm(CorporateContactForms.Remove))
      .subscribe(() => applyTransaction(() => this.store.remove(id)));
  }
}

export const corporateContactService = new CorporateContactService(
  corporateContactStore,
  new Reservations.CorporateContactApi()
);
