import {
  ContactInformationForm,
  EnumValueDisplay,
  ErrorDisplay,
  FormSelect,
  FormTextField,
  NameForm,
  SubmitButton,
} from '@lib/common';
import { FormStatus } from '@lib/state';
import { CorporateContactType } from '@lib/state/api/generated/reservations';
import { Grid, MenuItem } from '@material-ui/core';
import { CorporateContactModel } from 'app/state';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  contact?: CorporateContactModel;
  status: FormStatus;
  error?: Error;
}

export default function CorporateContactForm({ contact, status, error }: Readonly<Props>) {
  const contactTypes = useMemo(
    () =>
      Object.keys(CorporateContactType)
        .filter(key => key !== 'Other')
        .sort((a, b) => a.localeCompare(b))
        .concat('Other'),
    []
  );
  const { watch } = useFormContext();

  const contactType = watch('corporateContactType');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <NameForm name={contact?.name} optional={true} />
      </Grid>
      <Grid item xs={12}>
        <ContactInformationForm
          email={contact?.contact?.email}
          phone={contact?.contact?.phone}
          required="email"
        />
      </Grid>
      <Grid item xs={12}>
        <FormSelect
          label="Contact Type"
          name="corporateContactType"
          data-testid="formStateSelect"
          fullWidth
          required
          defaultValue={contact?.corporateContactType ?? CorporateContactType.Sales}
        >
          {contactTypes.map(x => (
            <MenuItem key={x} value={x}>
              <EnumValueDisplay value={x} />
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      {contactType === 'Other' && (
        <Grid item xs={12}>
          <FormTextField name="otherContactType" fullWidth label="Other Contact Type" />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormTextField name="notes" fullWidth multiline={true} label="Notes" />
      </Grid>
      <Grid item xs={12}>
        <ErrorDisplay error={error} />
        <SubmitButton
          variant="contained"
          color="secondary"
          fullWidth
          pending={status === FormStatus.Pending}
          disabled={status === FormStatus.Pending}
          data-testid="addDelegateSubmitButton"
        >
          Submit
        </SubmitButton>
      </Grid>
    </Grid>
  );
}
