import { QueryEntity, Order } from '@datorama/akita';
import { switchMap, map, distinctUntilChanged } from 'rxjs/operators';
import isEqual from 'lodash.isequal';

import { groupBy, orderByDesc, map as mapTo, orderBy } from '@lib/state';
import { RoomRatePlanState, roomRatePlanStore } from './room-rate-plan.store';
import { RatePlanSort, RoomRatePlan } from './room-rate-plan.model';

const sortBy = (a: RoomRatePlan, b: RoomRatePlan, { ui: { ratePlanSort } }: RoomRatePlanState) => {
  switch (ratePlanSort) {
    case RatePlanSort.EndDate:
      return a.endDate.getTime() - b.endDate.getTime();
    case RatePlanSort.Priority:
    default:
      return b.priority - a.priority;
  }
};

export class RoomRatePlanQuery extends QueryEntity<RoomRatePlanState> {
  ratePlans = this.select(({ ui }) => ui).pipe(
    switchMap(({ propertyId, roomTypeId, corporateAccountId, showDisabled }) =>
      this.selectAll({
        filterBy: [
          x => x.propertyId === propertyId,
          x => x.roomTypeId === roomTypeId || roomTypeId == null,
          x =>
            x.affiliation?.corporateAccountId === corporateAccountId || corporateAccountId === '',
          x => x.disabledAt == null || showDisabled,
        ],
        sortBy,
      })
    ),
    map(ratePlans => groupBy(ratePlans, x => x.rateKey)),
    map(groups => mapTo(groups, plans => [...orderByDesc(plans, x => x.disabledAt)][0])),
    map(plans => Array.from(plans))
  );

  corporateRatePlans = this.select(({ ui }) => ui).pipe(
    switchMap(({ corporateAccountId }) =>
      this.selectAll({
        filterBy: [
          x => x.affiliation?.corporateAccountId === corporateAccountId,
          x => x.disabledAt == null,
        ],
      })
    ),
    map(ratePlans => groupBy(ratePlans, x => x.rateKey)),
    map(groups => mapTo(groups, plans => [...orderByDesc(plans, x => x.disabledAt)][0])),
    map(plans =>
      Array.from(
        orderBy(
          plans,
          x => x.order,
          (a, b) => a - b
        )
      )
    )
  );

  activeRatePlan = this.selectActive().pipe(distinctUntilChanged<RoomRatePlan>(isEqual));

  filters = this.select(({ ui }) => ui);

  activeRatePlanHistory = this.selectActive(x => x.rateKey).pipe(
    switchMap(key =>
      this.selectAll({
        filterBy: x => x.rateKey === key,
        sortBy: 'createdAt',
        sortByOrder: Order.DESC,
      })
    )
  );
}

export const roomRatePlanQuery = new RoomRatePlanQuery(roomRatePlanStore);
