import { filterNil, Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

import { ReservationQuery, reservationQuery } from '../reservation';
import { roomsQuery } from '../rooms';
import { CheckInState, CheckInStore, checkInStore } from './check-in.store';

export class CheckInQuery extends Query<CheckInState> {
  constructor(store: CheckInStore, private readonly reservationQuery: ReservationQuery) {
    super(store);
  }

  requirements = this.select(x => x.requirements);

  roomOptions = roomsQuery.rooms.pipe(
    switchMap(rooms => {
      return this.select(x => x.roomOptions).pipe(
        map(options => {
          return options
            ?.filter(f => rooms.some(s => s.id === f))
            .map(o => rooms.find(f => f.id === o)!);
        })
      );
    })
  );

  guestStay = combineLatest([
    this.reservationQuery.activeReservation.pipe(filterNil),
    this.select('rooms'),
    this.select('messages'),
  ]).pipe(
    debounceTime(0),
    map(([reservation, rooms, messages]) => ({
      reservation,
      rooms: rooms ?? [],
      messages: messages ?? [],
    }))
  );
}

export const checkInQuery = new CheckInQuery(checkInStore, reservationQuery);
